import { useMergeRefs } from '@floating-ui/react'
import { useIsInViewport } from '@kaliber/use-is-in-viewport'

import { useBackgroundVideo } from '/machinery/useBackgroundVideo'

import styles from './BackgroundVideo.css'

export function BackgroundVideo({ poster, sources, layoutClassName = undefined }) {
  const videoRef = React.useRef(null)
  const { src, ref } = useBackgroundVideo({ sources })
  const { ref: visibilityRef, isInViewport } = useIsInViewport()

  React.useEffect(
    () => {
      if (isInViewport) playSafe(videoRef.current)
      else videoRef.current.pause()
    },
    [isInViewport]
  )

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <video
        loop
        muted
        autoPlay
        playsInline
        disablePictureInPicture
        controlsList="nodownload"
        ref={useMergeRefs([ref, videoRef, visibilityRef])}
        className={styles.video}
        {...{ poster, src }}
      />
    </div>
  )
}

function playSafe(video) {
  return Promise.resolve(video.play()).catch(_ => {})
}
