import { useVideoQualityLevel } from '/machinery/useVideoQualityLevel'
import { useIsInViewport } from '@kaliber/use-is-in-viewport'
import { mergeRefs } from '/machinery/mergeRefs'

export function useBackgroundVideo({ sources }) {
  const videoRef = React.useRef(null)
  const { src, ref: qualityRef } = useVideoQualityLevel({ sources })
  const { ref: visibilityRef, isInViewport } = useIsInViewport()

  React.useEffect(
    () => {
      if (isInViewport) playSafe(videoRef.current)
      else videoRef.current.pause()
    },
    [isInViewport]
  )

  return { src, ref: mergeRefs(videoRef, visibilityRef, qualityRef) }
}

function playSafe(video) {
  return Promise.resolve(video.play()).catch(_ => {})
}
